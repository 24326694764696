import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import ContentContainer from "../shared/ContentContainer";
import Notification from "../shared/Notification";
import AuthForm from "./components/AuthForm";
import AuthHeader from "./components/AuthHeader";
import "./Auth.scss";

const Auth = () => {
  const navigate = useNavigate();
  const { token } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/player");
    }
  }, [token]);

  return (
    <ContentContainer>
      <Notification />
      <div className="auth">
        <AuthHeader />
        <AuthForm />
      </div>
    </ContentContainer>
  );
};

export default Auth;
