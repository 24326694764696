import { useContext, useState, useEffect } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import Close from "../../shared/Close";
import Notification from "../../shared/Notification";
import AppHeader from "../../shared/AppHeader";
import Comment from "../../comments/components/PostComment";
import Loader from "../../shared/Loader";

const PostComments = (props) => {
  const { sendRequest, isLoading } = useHttpClient();
  const context = useContext(Context);

  useEffect(() => {
    context.setPostComments([]);
    const fetchComments = async () => {
      const responseData = await sendRequest(
        `https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/comments/post/${context.activePost}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + context.token,
          "Content-Type": "application/json",
        }
      );
      if (responseData) context.setPostComments(responseData.comments);
    };

    fetchComments();
  }, []);

  return (
    <div className="col col__1 post_comments">
      <Close
        onClick={() => {
          context.setTab("community");
        }}
      />
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Comments" />
      <div>
        {context.postComments &&
          context.postComments
            .filter((c) => c.creator._id === context.userId)
            .map((c) => <Comment {...c} key={c._id} />)}

        {context.postComments &&
          context.postComments
            .filter((c) => c.creator._id !== context.userId)
            .map((c) => <Comment {...c} key={c._id} />)}
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default PostComments;
