import Placeholder from "../../../assets/placeholder-tile.jpg";
import "./UserTile.scss";

const UserTile = (props) => {
  const {
    id,
    admin,
    verified,
    img,
    name,
    onAdminChange,
    onVerificationChange,
  } = props;

  // Handle admin status toggle
  const clickAdminHandler = () => {
    onAdminChange(id, !admin);
  };

  // Handle verification status toggle
  const clickVerifyHandler = () => {
    onVerificationChange(id, !verified);
  };

  return (
    <div className="user">
      <div className="user__info">
        <img src={img || Placeholder} alt={`${name}'s profile`} />

        <div className="user__text">
          <h3>{name}</h3>
          <span>{admin ? "admin" : "user"}</span>
        </div>
      </div>

      <div className="user__buttons">
        <button onClick={clickVerifyHandler}>
          {verified ? "Remove Verification" : "Verify"}
        </button>
        <button onClick={clickAdminHandler}>
          {admin ? "Remove Admin" : "Make Admin"}
        </button>
      </div>
    </div>
  );
};

export default UserTile;
