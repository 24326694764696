import { useContext } from "react";
import { Context } from "../../../utils/context";
import { firebaseDelete } from "../../../utils/upload-hook";
import AppHeader from "../../shared/AppHeader";
import ActiveSongPreview from "../../active-song/components/ActiveSongPreview";
import SongProgressBar from "../../active-song/components/SongProgressBar";
import Close from "../../shared/Close";
import "./ActiveAISong.scss";

const ActiveAISong = () => {
  const context = useContext(Context);

  const deleteSong = () => {
    if (context.activeAISong.songPath) {
      firebaseDelete(context.activeAISong.songPath);
      context.setActiveAISong(null);
      context.setTab();
    }
  };

  return (
    <div className="col col__2 active_song">
      <div className="active_ai_song_header">
        <Close
          onClick={() => {
            deleteSong();
          }}
        />
      </div>
      <AppHeader text="Preview remix" />
      {context.activeAISong && (
        <>
          <ActiveSongPreview song={context.activeAISong} />
          <SongProgressBar />
        </>
      )}
    </div>
  );
};

export default ActiveAISong;
