import { Link } from "react-router-dom";
import "./Link.scss";

const ButtonLink = (props) => {
  return (
    <Link
      to={props.to}
      className={props.disabled ? "link link--disabled" : "link"}
    >
      {props.text}
    </Link>
  );
};

export default ButtonLink;
