import React, { useEffect, useState } from "react";
import "./GeneratingLoader.scss";

const GeneratingLoader = ({ config }) => {
  const [animationStarted, setAnimationStarted] = useState(false);
  const [consoleLines, setConsoleLines] = useState([]);
  const [progress, setProgress] = useState(0);
  const [initialMessageVisible, setInitialMessageVisible] = useState(true);

  useEffect(() => {
    if (!animationStarted) {
      initializeAnimation(config);
      setAnimationStarted(true);
    }
  }, [config]);

  const startAnimation = (config) => {
    const { artistName } = config;
    const lines = [
      `ACCESSING THE ${artistName || "{ツ}"} HARD DRIVE [EST. WAIT TIME 3 MIN]`,
      'Access code: "HAPPY MONDAY"...',
      "Downloading stem: vocals.wav...",
      "Downloading stem: melody.wav...",
      "Downloading stem: drums.wav...",
      "Downloading stem: 808s.wav...",
      "Downloading stem: sample.wav...",
      "Adjusting BPM and Key to target: Processing...",
      "Analyzing file: vocals.wav...",
      "Analyzing file: melody.wav...",
      "Analyzing file: drums.wav...",
      "Analyzing file: 808s.wav...",
      "Analyzing file: sample.wav...",
      "// Initialize AI model for sound generation",
      'const aiModel = initializeAIModel("music_generator_v2");',
      "// Generate new melodies",
      "const newMelodies = aiModel.generateMelodies(genre, key, tempo);",
      "// Create drum patterns",
      "const drumPatterns = aiModel.createDrumPatterns(style, bpm);",
      "// Synthesize bass lines",
      "const bassLines = aiModel.synthesizeBass(key, progression);",
      "// Generate FX and textures",
      "const fxLayers = aiModel.createAmbientTextures(mood, intensity);",
      "// Combine all generated elements",
      "const newStems = combineGeneratedElements(newMelodies, drumPatterns, bassLines, fxLayers);",
      "Files located in the cloud...",
      "Unreleased demo will be in your library shortly ツ",
    ];

    let lineIndex = 0;
    const totalLines = lines.length;
    const delay = 500;

    const updateConsole = () => {
      if (lineIndex < totalLines) {
        setConsoleLines((prevLines) => [...prevLines, lines[lineIndex]]);
        lineIndex++;
        const progress = (lineIndex / totalLines) * 100;
        setProgress(progress);
        setTimeout(updateConsole, delay);
      }
    };

    updateConsole();
  };

  const initializeAnimation = (config) => {
    setInitialMessageVisible(true);
    setTimeout(() => {
      setInitialMessageVisible(false);
      startAnimation(config);
    }, 10000); // 10 seconds delay before the animation starts
  };

  return (
    <div className="hard-drive-access">
      <div id="title">LOADING...</div>
      {initialMessageVisible && (
        <div id="initial-message">
          DISCLAIMER: ACCESSING THIS HARD DRIVE TAKES 3 MINUTES ON AVERAGE. YOU
          CAN LEAVE, CHECK BACK IN LATER, AND THE DEMO WILL APPEAR IN YOUR
          LIBRARY SHORTLY.
          <br />
          <br />
          NOTE: THIS IS AN UNRELEASED DEMO AND THE PRODUCTION IS NOT 100%
          PRODUCED BY THE ARTIST OR PRODUCER. YOU ARE THE ONLY PERSON TO HAVE
          ACCESS TO THIS DEMO ツ
        </div>
      )}
      <div id="console">
        {consoleLines.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
      <div id="progress-container">
        <div id="progress-bar" style={{ width: `${progress}%` }}></div>
        <div id="progress-text">{Math.round(progress)}%</div>
      </div>
    </div>
  );
};

export default GeneratingLoader;
