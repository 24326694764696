import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Community from "../community/Community";
import PostComments from "../community/components/PostComments";
import Users from "../users/Users";
import SongsSettings from "../songs-settings/SongsSettings";
import ActiveSong from "../active-song/ActiveSong";
import AppPlayerWrapper from "../shared/AppPlayerWrapper";
import ContentContainer from "../shared/ContentContainer";
import SongsTab from "../songs/SongsTab";
import SongComments from "../comments/SongComments";
import Menu from "../menu/Menu";
import AccountSettings from "../account-settings/AccountSettings";
import UploadSong from "../songs-settings/components/UploadSong";
import GenerateTab from "../generate/GenerateTab";
import EditSong from "../songs-settings/components/EditSong";
import EditAI from "../songs-settings/components/EditAI";
import Generating from "../generate/components/Generating";
import ActiveAISong from "../generate/components/ActiveAISong";
import RemixInfo from "../generate/components/RemixInfo";
import EditRemix from "../generate/components/EditRemix";
import "./MainApp.scss";
import Nav from "../nav/Nav";
import GenerateAdminSettings from "../generate/components/GenerateAdminSettings";
import ControlPanel from "../control-panel/ControlPanel";

const MainApp = () => {
  const navigate = useNavigate();
  const { tab, token, subscriptionActive } = useContext(Context);

  useEffect(() => {
    const userData = localStorage.getItem("userData");

    if (!userData) {
      navigate("/login");
    }

    if (userData && !subscriptionActive) {
      navigate("/subscribe");
    }
  }, [token]);

  return (
    <AppPlayerWrapper>
      {tab === "menu" && <Menu />}
      {tab === "account_settings" && <AccountSettings />}
      {tab === "users" && <Users />}
      {tab === "songs_settings" && <SongsSettings />}
      {tab === "upload_song" && <UploadSong />}
      {tab === "edit_song" && <EditSong />}
      {tab === "edit_song_ai" && <EditAI />}

      <ContentContainer no_overflow>
        <div className="cols">
          {!tab && <SongsTab />}
          {tab === "generate-admin-settings" && <GenerateAdminSettings />}
          {tab === "generate" && <GenerateTab />}
          {tab === "generating" && <Generating />}
          {tab === "generated-results" && <RemixInfo />}
          {tab === "edit-remix" && <EditRemix />}
          {tab === "comments" && <SongComments />}
          {(tab === "community" || tab === "community_comments") && (
            <Community />
          )}
          {tab === "control-panel" && <ControlPanel />}
          {tab === "community_comments" && <PostComments />}
          {tab !== "generating" && <hr />}
          {tab === "generated-results" ? <ActiveAISong /> : <ActiveSong />}
        </div>
        <Nav />
      </ContentContainer>
    </AppPlayerWrapper>
  );
};

export default MainApp;
