import { useContext, useState } from "react";
import { Context } from "../../../utils/context";
import { firebaseDelete } from "../../../utils/upload-hook";
import "./PlayerButtons.scss";
import { useHttpClient } from "../../../utils/http-hook";

const AIButtons = () => {
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  const deleteSong = () => {
    if (context.activeAISong.songPath) {
      firebaseDelete(context.activeAISong.songPath);
      context.setActiveAISong(null);
      context.setTab();
    }
  };

  const regenerateHandler = () => {
    context.generateSong(context.activeAISong.originalSongId);
  };

  const spedUp = async () => {
    if (context.aiLoading) return;

    context.setAiLoading(true);
    const result = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/sped-up",
      "POST",
      JSON.stringify({
        songURL: context.activeAISong.songURL,
        songPath: context.activeAISong.songPath,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (result) {
      context.setActiveAISong((song) => {
        return { ...song, songURL: result.songUrl };
      });
    }
  };

  const slowDown = async () => {
    if (context.aiLoading) return;

    context.setAiLoading(true);
    const result = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/slow-down",
      "POST",
      JSON.stringify({
        songURL: context.activeAISong.songURL,
        songPath: context.activeAISong.songPath,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (result) {
      context.setActiveAISong((song) => {
        return { ...song, songURL: result.songUrl };
      });
    }
  };

  return (
    <div className="player_buttons">
      <div className="player_buttons__row">
        <div className="player_button" onClick={deleteSong}>
          <div className="player_button__num">01</div>
          <p>Delete</p>
        </div>

        <div className="player_button" onClick={regenerateHandler}>
          <div className="player_button__num">02</div>
          <p>Retry</p>
        </div>
      </div>

      <div className="player_buttons__row">
        <div className="player_button" onClick={spedUp}>
          <div className="player_button__num">03</div>
          <p>Sped up</p>
        </div>

        <div className="player_button" onClick={slowDown}>
          <div className="player_button__num">04</div>
          <p>Slow down</p>
        </div>
      </div>
    </div>
  );
};

export default AIButtons;
