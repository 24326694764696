import { useContext, useState, useRef } from "react";
import { Context } from "../../utils/context";
import { firebaseDelete, firebaseUpload } from "../../utils/upload-hook";
import { useHttpClient } from "../../utils/http-hook";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import Placeholder from "../../assets/placeholder-tile.jpg";
import Clear from "../../assets/close-accent.svg";
import Input from "../shared/Input";
import Button from "../shared/Button";
import Loader from "../shared/Loader";
import CancelSubscription from "./components/CancelSubscription";
import "./AccountSettings.scss";

const AccountSettings = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading, setIsLoading } = useHttpClient();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [subCanceled, setSubCanceled] = useState(
    context.user.subscriptionCanceled
  );
  const [name, setName] = useState(context.user.name);
  const [email, setEmail] = useState(context.user.email);
  const [password, setPassword] = useState("");

  const ref = useRef();

  const [accountImg, setAccountImg] = useState(context.user.img || Placeholder);
  const [accountImgPath, setAccountImgPath] = useState(context.user.imgPath);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (accountImgPath) {
      firebaseDelete(accountImgPath);
    }

    if (accountImg !== Placeholder) {
      const path =
        "Pictures/UserPics/" +
        context.userId +
        "." +
        ref.current.files[0].name.split(".").slice(-1);

      const [getUploadTask, getDownloadURL] = firebaseUpload(
        path,
        ref.current.files[0]
      );

      const uploadTask = getUploadTask();
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            saveUserHandler(downloadURL, path);
          });
        }
      );
    } else {
      saveUserHandler("", "");
    }
  };

  const saveUserHandler = async (imgURL, path) => {
    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/users/" +
        context.userId,
      "POST",
      JSON.stringify({
        name,
        email,
        password,
        img: imgURL,
        imgPath: path,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    context.setUser({
      name,
      email,
      img: imgURL,
      path,
      subscriptionCanceled: subCanceled,
    });

    setAccountImgPath(path);
  };

  const fileChangeHandler = () => {
    if (ref.current.files[0]) {
      const fr = new FileReader();

      fr.onload = function () {
        setAccountImg(fr.result);
      };

      fr.readAsDataURL(ref.current.files[0]);
    } else {
      setAccountImg(Placeholder);
    }
  };

  const clearImgHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    ref.current.value = "";
    setAccountImg(Placeholder);
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Account" />
      <Close onClick={() => context.setTab(null)} />

      <div className="account">
        <form className="account__form" onSubmit={submitHandler}>
          <label className="account__file_label" htmlFor="file">
            {accountImg !== Placeholder && (
              <div className="account__clear" onClick={clearImgHandler}>
                <img src={Clear} alt="Clear" />
              </div>
            )}

            <img className="account__img" alt="" src={accountImg} />
            <input
              ref={ref}
              onChange={fileChangeHandler}
              id="file"
              className="account__file_input"
              type="file"
              accept="image/*"
            />
          </label>

          <div className="account__inputs">
            <Input
              placeholder="Username"
              value={name}
              type="text"
              onInput={(e) => {
                setName(e.target.value);
                context.setError("");
              }}
              required
            />
            <Input
              placeholder="Email"
              value={email}
              type="email"
              onInput={(e) => {
                setEmail(e.target.value);
                context.setError("");
              }}
              required
            />
            <Input
              placeholder="Password"
              value={password}
              type="password"
              onInput={(e) => {
                setPassword(e.target.value);
                context.setError("");
              }}
            />
            <Button text="Save" />
          </div>
        </form>

        {!isLoading && !context.admin && (
          <div className="cancel_subscription__container">
            {!subCanceled && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setCancelModalOpen(true);
                }}
                className="cancel_subscription"
              >
                Cancel Subscription
              </button>
            )}

            <a
              href="/subscribe?no_redirect=true"
              className="cancel_subscription"
            >
              Update Subscription
            </a>
          </div>
        )}

        {!context.admin && subCanceled && (
          <p className="cancel_subscription_par">
            Your subscription is canceled
          </p>
        )}

        {cancelModalOpen && (
          <CancelSubscription
            clear={() => setCancelModalOpen(false)}
            cancel={() => setSubCanceled(true)}
          />
        )}

        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default AccountSettings;
