import { useContext, useState } from "react";
import ButtonLink from "../../shared/ButtonLink";
import SubscribeTile from "./SubscribeTile";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import "./SubscribeBody.scss";

const PLANS = [
  {
    price: "$1/MO",
    link: "https://buy.stripe.com/test_9AQ5lj51h0UB6cw00z",
    features: ["UNRELEASED MUSIC", "[3] REMIX CREDITS", "HARD DRIVE ACCESS"],
  },
  {
    price: "$7/MO",
    link: "https://buy.stripe.com/test_eVafZX0L19r7gRabJi",
    features: ["UNRELEASED MUSIC", "[10] REMIX CREDITS", "MORE EDIT FEATURES"],
  },
  {
    price: "$20/MO",
    link: "https://buy.stripe.com/test_7sI5lj0L1cDj30kfZz",
    features: ["UNRELEASED MUSIC", "[25] REMIX CREDITS", "EXCLUSIVE OFFERS"],
  },
];

const CREDITS = [
  {
    link: "-",
    price: "$10",
    features: ["REQUIRES PLAN", "[25] REMIX CREDITS", "ONE-TIME FEE"],
  },
  {
    link: "--",
    price: "$25",
    features: ["REQUIRES PLAN", "[50] REMIX CREDITS", "ONE-TIME FEE"],
  },
];

const SubscribeBody = () => {
  const context = useContext(Context);
  const [activeLink, setActiveLink] = useState("");

  return (
    <div className="subscribe_body">
      <div className="subscribe_body__tiles">
        <div className="subsribe_body__row">
          <AppHeader text="Choose a plan" />
          {PLANS.map((p) => (
            <SubscribeTile
              {...p}
              onClick={() => setActiveLink(p.link)}
              active={activeLink === p.link}
            />
          ))}
        </div>

        <div className="subsribe_body__row">
          <AppHeader text="Remix Credits" />
          {CREDITS.map((p) => (
            <SubscribeTile
              {...p}
              onClick={() => setActiveLink(p.link)}
              active={activeLink === p.link}
            />
          ))}
        </div>
      </div>

      <ButtonLink text="Back" to="/" />
      <ButtonLink
        text="HACK THE HARD DRIVE"
        to={`${activeLink}?prefilled_email=${context.email}`}
        disabled={!activeLink}
      />
    </div>
  );
};

export default SubscribeBody;
