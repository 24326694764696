import { useContext, useState } from "react";
import { Context } from "../../utils/context";
import ModalContainer from "../shared/ModalContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import Close from "../shared/Close";
import SongTile from "../songs/components/SongTile";
import Input from "../shared/Input";
import Button from "../shared/Button";
import "./SongsSettings.scss";

const SongsSettings = () => {
  const context = useContext(Context);
  const [search, setSearch] = useState("");
  const [songs, setSongs] = useState(context.songs);

  const searchHandler = (value) => {
    const param = value.toLowerCase().trim();
    setSongs((songs) =>
      param
        ? context.songs.filter(
            (s) =>
              s.artist.toLowerCase().indexOf(param) !== -1 ||
              s.name.toLowerCase().indexOf(param) !== -1
          )
        : context.songs
    );
    setSearch(value);
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Songs" />
      <Close onClick={() => context.setTab(null)} />

      <div className="songs_settings__header">
        <Input
          placeholder="Search"
          value={search}
          type="text"
          onInput={(e) => searchHandler(e.target.value)}
          required
        />

        <Button text="upload" onClick={() => context.setTab("upload_song")} />
      </div>

      <div className="songs_settings">
        {songs &&
          songs.map((s, i) => (
            <SongTile
              {...s}
              key={i}
              onClick={() => {
                context.setEditSong(s._id);
                context.setTab("edit_song");
              }}
              settings
            />
          ))}
      </div>
    </ModalContainer>
  );
};

export default SongsSettings;
