import { NavLink } from "react-router-dom";
import "./AuthHeader.scss";

const AuthHeader = () => {
  return (
    <div className="auth__header">
      <NavLink to="/login">Login</NavLink>
      <NavLink to="/signup">Signup</NavLink>
    </div>
  );
};

export default AuthHeader;
