import { useContext } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import SongTile from "../../songs/components/SongTile";
import "./PostModal.scss";

const SongModal = (props) => {
  const context = useContext(Context);

  return (
    <div className="post_modal">
      <div className="post_modal__backdrop" onClick={props.closeModal}></div>
      <div className="post_modal__container">
        <Close onClick={props.closeModal} />
        <AppHeader text="Select a song" />

        <div className="post_modal__songs">
          {context.songs.map((s, i) => (
            <SongTile
              {...s}
              key={i}
              post
              postActive={s._id === props.song}
              onClick={() => props.setSong(s._id)}
            />
          ))}
        </div>

        <div className="post_modal__buttons">
          <div className="button" onClick={() => props.setSong(null)}>
            Clear
          </div>
        </div>
      </div>
    </div>
  );
};

export default SongModal;
