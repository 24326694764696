import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import ContentContainer from "../shared/ContentContainer";
import Notification from "../shared/Notification";
import AppHeader from "../shared/AppHeader";
import SubscribeBody from "./components/SubscribeBody";
import "./Subscribe.scss";

const Subscribe = () => {
  const navigate = useNavigate();
  const context = useContext(Context);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (context.subscriptionActive && !urlParams.get("no_redirect")) {
      navigate("/player");
    }
  }, [context.token, context.subscriptionActive, navigate]);

  return (
    <ContentContainer>
      <Notification text="PICK A PLAN" />
      <div className="subscribe">
        <SubscribeBody />
      </div>
    </ContentContainer>
  );
};

export default Subscribe;
