import { useContext, useEffect, useRef } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Notification from "../../shared/Notification";
import Input from "../../shared/Input";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Close from "../../shared/Close";
import Button from "../../shared/Button";
import "./RemixInfo.scss";

const GenerateAdminSettings = () => {
  const context = useContext(Context);
  const song = context.songs
    ? context.songs.find((s) => s._id === context.activeSong)
    : null;

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Generate" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <div className="remix_info__details">
          <img src={song.imgURL || Placeholder} alt="" />
          <div>
            <h3>{song.name}.MP5</h3>
            <p>{song.artist}</p>
            <p>MP5PLAYER.NET</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <Input
            placeholder="Moods"
            value={context.customMoods}
            type="text"
            onInput={(e) => context.setCustomMoods(e.target.value)}
          />
          <Input
            placeholder="Genres"
            value={context.customGenre}
            type="text"
            onInput={(e) => context.setCustomGenre(e.target.value)}
          />
          <Input
            placeholder="Mute Stems"
            value={context.customMuteStems}
            type="text"
            onInput={(e) => context.setCustomMuteStems(e.target.value)}
          />
          <Button
            text="Generate"
            onClick={() =>
              context.generateSong(context.activeSong, context.mode)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateAdminSettings;
