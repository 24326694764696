import { Context } from "../../utils/context";
import AppWrapper from "./AppWrapper";

const AppContextWrapper = (props) => {
  return (
    <Context.Provider value={props.value}>
      <AppWrapper>{props.children}</AppWrapper>
    </Context.Provider>
  );
};

export default AppContextWrapper;
