import { useContext } from "react";
import { useHttpClient } from "../../../utils/http-hook";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Notification from "../../shared/Notification";
import Input from "../../shared/Input";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Button from "../../shared/Button";
import { firebaseDelete } from "../../../utils/upload-hook";
import Loader from "../../shared/Loader";
import Delete from "../../../assets/delete-light.svg";
import Download from "../../../assets/download.svg";
import "./RemixInfo.scss";

const RemixInfo = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const deleteSong = () => {
    if (context.activeAISong.songPath) {
      firebaseDelete(context.activeAISong.songPath);
      context.setActiveAISong(null);
      context.setTab();
    }
  };

  //Duplicate Song
  const duplicateSong = async () => {
    const result = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/duplicate",
      "POST",
      JSON.stringify({
        songUrl: context.activeAISong.songURL,
        songPath: context.activeAISong.songPath,
        originalSongId: context.activeAISong.originalSongId,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    if (result) {
      context.setActiveAISong(null);
      context.setActiveRemix(result.song);
      context.setSongs((s) => [...s, result.song]);
      context.setActiveSong(result.song._id);
      context.setTab("edit-remix");
    }
  };

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Song Info" />

      <div className="remix_info">
        <div className="remix_info__details">
          <img src={context.activeAISong.imgURL || Placeholder} alt="" />
          <div>
            <h3>{context.activeAISong.name}.MP5</h3>
            <p>{context.activeAISong.artist}</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <Input
            placeholder="Song Name"
            value={context.activeAISong.name}
            type="text"
            onInput={(e) =>
              context.setActiveAISong((song) => {
                return { ...song, name: e.target.value };
              })
            }
            required
          />
          {!isLoading && (
            <>
              <Button text="Continue" onClick={duplicateSong} />

              <div className="remix_info__buttons">
                <div className="remix_info__button" onClick={deleteSong}>
                  <span>Delete</span>
                  <img src={Delete} alt="" />
                </div>

                <a
                  className="remix_info__button"
                  href={context.activeAISong.songURL}
                  download={context.activeAISong.name}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>Download</span>
                  <img src={Download} alt="" />
                </a>
              </div>
            </>
          )}
          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default RemixInfo;
