import { useContext } from "react";
import { Context } from "../../utils/context";
import Nav from "../nav/Nav";
import AppHeader from "../shared/AppHeader";
import ActiveSongPreview from "./components/ActiveSongPreview";
import SongProgressBar from "./components/SongProgressBar";
import "./ActiveSong.scss";

const ActiveSong = () => {
  const { songs, activeSong, tab } = useContext(Context);
  const song =
    songs && activeSong ? songs.find((s) => s._id === activeSong) : null;

  return (
    <div className="col col__2 active_song">
      <Nav />
      {tab !== "generating" && <AppHeader text="NOW PLAYING" />}

      {song && (
        <>
          <ActiveSongPreview song={song} />
          <SongProgressBar />
        </>
      )}
    </div>
  );
};

export default ActiveSong;
