import CloseIcon from "../../assets/close.svg";
import "./Close.scss";

const Close = (props) => {
  return (
    <img
      onClick={props.onClick}
      src={CloseIcon}
      className="close"
      alt="close"
    />
  );
};

export default Close;
