import Preview from "../../../assets/placeholder-tile.jpg";
import "./SubscribeTile.scss";

const SubscribeTile = (props) => {
  return (
    <div
      className={
        props.active
          ? "subscribe_tile subscribe_tile--active"
          : "subscribe_tile"
      }
      onClick={props.onClick}
    >
      <img src={Preview} alt="" />
      <div>
        <h3>{props.price}</h3>
        {props.features.map((f) => (
          <p>ツ {f}</p>
        ))}
      </div>
    </div>
  );
};

export default SubscribeTile;
