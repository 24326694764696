import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Input from "../../shared/Input";
import "./PostModal.scss";

function convertYouTubeLinkToEmbed(link) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|v\/|embed\/|user\/(?:\w+#)?\w+\/))([^&?\n]+)/;
  const match = link.match(regex);
  if (match && match[1]) {
    const videoId = match[1];
    const embedLink = `https://www.youtube.com/embed/${videoId}`;
    return embedLink;
  } else {
    return null;
  }
}

const YoutubeModal = (props) => {
  const addLink = (link) => {
    let formattedLink = convertYouTubeLinkToEmbed(link);

    if (formattedLink) {
      props.setYoutube(formattedLink);
    }
  };

  return (
    <div className="post_modal">
      <div className="post_modal__backdrop" onClick={props.closeModal}></div>
      <div className="post_modal__container">
        <Close onClick={props.closeModal} />
        <AppHeader text="Add A YouTube Video" />

        <Input
          placeholder="Link"
          value={props.youtube}
          type="text"
          onInput={(e) => addLink(e.target.value)}
          required
        />

        {props.youtube && (
          <div className="post_modal__embed">
            <iframe
              src={props.youtube}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        )}

        <div className="post_modal__buttons">
          <div className="button" onClick={() => props.setYoutube("")}>
            Clear
          </div>
        </div>
      </div>
    </div>
  );
};

export default YoutubeModal;
