import { useContext } from "react";
import { Context } from "../../utils/context";
import Moon from "../../assets/moon.svg";
import Sun from "../../assets/sun.svg";
import "./ThemeToggle.scss";

const ThemeToggle = () => {
  const context = useContext(Context);

  const changeTheme = () => {
    const newTheme = context.theme === "light" ? "dark" : "light";
    const userData = JSON.parse(localStorage.getItem("userData"));
    const newUserData = { ...userData, theme: newTheme };
    localStorage.setItem("userData", JSON.stringify(newUserData));

    context.setTheme(newTheme);
  };

  return (
    <div
      className={
        context.theme === "light"
          ? "theme_toggle theme_toggle--light"
          : "theme_toggle"
      }
      onClick={changeTheme}
    >
      <div>
        <img src={context.theme === "light" ? Sun : Moon} alt="" />
      </div>
    </div>
  );
};

export default ThemeToggle;
