import SongTile from "./SongTile";
import "./SongsList.scss";

const SongsList = (props) => {
  return (
    <div className="songs_list">
      {props.songs.map((s, i) => (
        <SongTile {...s} num={i + 1} key={i} />
      ))}
    </div>
  );
};

export default SongsList;
