import Player from "../player/Player";
import "./AppPlayerWrapper.scss";

const AppPlayerWrapper = (props) => {
  return (
    <div className="player_wrapper">
      {props.children}
      <Player />
    </div>
  );
};

export default AppPlayerWrapper;
