import { useContext } from "react";
import { Context } from "../../utils/context";
import AppHeader from "../shared/AppHeader";
import SongsList from "../songs/components/SongsList";
import Notification from "../shared/Notification";

const GenerateTab = () => {
  const context = useContext(Context);
  const AIEnabledSongs = context.songs
    ? context.songs.filter((s) => s.AIEnabled)
    : [];

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      {AIEnabledSongs.length && (
        <>
          <AppHeader text="Hard Drive" />
          <SongsList songs={AIEnabledSongs} />
        </>
      )}
    </div>
  );
};

export default GenerateTab;
