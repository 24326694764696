import Chrome from "../../../assets/chrome.svg";
import "./GoogleSignIn.scss";

const GoogleSignIn = (props) => {
  return (
    <div className="google" onClick={props.onClick}>
      <img src={Chrome} alt="" />
      GOOGLE SIGN-IN
    </div>
  );
};

export default GoogleSignIn;
