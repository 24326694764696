import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../utils/context";
import "./SongProgressBar.scss";

const SongProgressBar = () => {
  const { currentTime, duration, setCurrentTime, audioRef, activeSong } =
    useContext(Context);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef();

  const updateSliderBackground = (value) => {
    const progress = (value / duration) * 100;
    sliderRef.current.style.background = `linear-gradient(to right, #2d5ffc 0%, #2d5ffc ${progress}%, #0f0f0f ${progress}%, #272727 100%)`;
  };

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    setIsDragging(true);
    updateSliderBackground(newValue); // Update background during drag
    sliderRef.current.value = newValue;
  };

  const handleMouseUp = () => {
    if (sliderRef.current && audioRef.current) {
      const newTime = Number(sliderRef.current.value);
      audioRef.current.currentTime = newTime; // Set audio current time when drag ends
      setCurrentTime(newTime); // Update context
    }
    setIsDragging(false);
  };

  // Sync slider value and background with currentTime
  useEffect(() => {
    if (!isDragging && sliderRef.current) {
      sliderRef.current.value = currentTime;
      updateSliderBackground(currentTime); // Update background with current time
    }
  }, [currentTime, isDragging, duration]);

  useEffect(() => {
    setCurrentTime(0);
    sliderRef.current.value = 0;
  }, [activeSong]);

  return (
    <div className="progress_bar">
      <input
        type="range"
        min="0"
        max={duration}
        step="0.1"
        onChange={handleSliderChange} // Update slider visually during drag
        onMouseUp={handleMouseUp} // Set new time after drag
        onTouchEnd={handleMouseUp} // For mobile devices
        ref={sliderRef} // Reference to sync with currentTime
      />

      <div className="progress_bar__time">
        <span>
          {Math.floor(currentTime / 60)}:
          {("0" + Math.floor(currentTime % 60)).slice(-2)}
        </span>

        <span>
          {Math.floor(duration / 60)}:
          {("0" + Math.floor(duration % 60)).slice(-2)}
        </span>
      </div>
    </div>
  );
};

export default SongProgressBar;
