import { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/context";
import AppHeader from "../shared/AppHeader";
import Notification from "../shared/Notification";
import Input from "../shared/Input";
import Select from "../shared/Select";
import Close from "../shared/Close";
import { useHttpClient } from "../../utils/http-hook";
import Placeholder from "../../assets/placeholder-tile.jpg";
import Download from "../../assets/download.svg";
import Loader from "../shared/Loader";

const ControlPanel = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const songs = context.songs
    ? context.songs.filter((s) => !s.AIGenerated && s.AIEnabled)
    : [];
  const artists = songs.map((s) => {
    return { name: s.artist, value: s.artist };
  });

  const [activeArtist, setActiveArtist] = useState(artists[0].value);
  const [artistSongs, setArtistSongs] = useState(
    songs.filter((s) => s.artist === artists[0].value)
  );
  const [activeArtistSong, setActiveArtistSong] = useState(
    songs.filter((s) => s.artist === artists[0].value)[0]
  );
  const [parameters, setParameters] = useState({
    moods: "",
    genres: "",
    muteStems: "",
  });

  useEffect(() => {
    context.setMode("Rock");
  }, []);

  useEffect(() => {
    setArtistSongs(songs.filter((s) => s.artist === activeArtist));
  }, [activeArtist]);

  useEffect(() => {
    setActiveArtistSong(artistSongs[0]);
  }, [artistSongs]);

  useEffect(() => {
    context.setActiveSong(activeArtistSong._id);

    setParameters({
      moods: activeArtistSong?.aiParameters?.[context.mode]?.moods || "",
      genres: activeArtistSong?.aiParameters?.[context.mode]?.genres || "",
      muteStems:
        activeArtistSong?.aiParameters?.[context.mode]?.muteStems || "",
    });
  }, [activeArtistSong, context.mode]);

  useEffect(() => {
    context.audioRef.current.pause();
  }, [context.activeSong]);

  const updateHandler = async () => {
    context.setSongs((songs) => {
      const index = songs.findIndex((s) => s._id === activeArtistSong._id);
      songs[index] = {
        ...songs[index],
        aiParameters: {
          ...songs[index].activeArtistSong,
          [context.mode]: {
            moods: parameters.moods,
            genres: parameters.genres,
            muteStems: parameters.muteStems,
          },
        },
      };
      return songs;
    });

    const data = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/songs/save-ai-params",
      "POST",
      JSON.stringify({
        songId: activeArtistSong._id,
        mode: context.mode,
        settings: parameters,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );
  };

  return (
    <div className="col col__1">
      <Notification text="Admin" />
      <AppHeader text="CONTROL PANEL" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <div className="remix_info__details">
          <img src={activeArtistSong.imgURL || Placeholder} alt="" />
          <div>
            <h3>{activeArtistSong.name}</h3>
            <p>{activeArtistSong.artist}</p>
            <p>{activeArtistSong.producer}</p>
            <p>{activeArtistSong.label}</p>
            <p>BPM: {activeArtistSong.bpm}</p>
            <p>Length: {activeArtistSong.length} [sec]</p>
            <p>
              Key: {activeArtistSong.key} {activeArtistSong.mode}
            </p>
            <p>Moods: {parameters.moods}</p>
            <p>Genres: {parameters.genres}</p>
            <p>Mute stems: {parameters.muteStems}</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <Select
            data={artists}
            default={activeArtist}
            onChange={(value) => setActiveArtist(value)}
          />

          <Select
            data={artistSongs.map((s) => {
              return { value: s._id, name: s.name };
            })}
            default={activeArtistSong._id}
            onChange={(value) =>
              setActiveArtistSong(artistSongs.find((s) => s._id === value))
            }
          />

          <div className="remix_info__buttons">
            <Input
              placeholder="Moods"
              value={parameters.moods}
              type="text"
              onInput={(e) =>
                setParameters((params) => {
                  return { ...params, moods: e.target.value };
                })
              }
            />

            <Input
              placeholder="Genres"
              value={parameters.genres}
              type="text"
              onInput={(e) =>
                setParameters((params) => {
                  return { ...params, genres: e.target.value };
                })
              }
            />
          </div>
          <div className="remix_info__buttons">
            <Input
              placeholder="Mute Stems"
              value={parameters.muteStems}
              type="text"
              onInput={(e) =>
                setParameters((params) => {
                  return { ...params, muteStems: e.target.value };
                })
              }
            />

            <div className="remix_info__button" onClick={updateHandler}>
              <span>Update</span>
              <img src={Download} alt="" />
            </div>
          </div>
        </div>
      </div>

      {isLoading && <Loader />}
    </div>
  );
};

export default ControlPanel;
