import { useContext } from "react";
import { Context } from "../../utils/context";
import SpeakerLeft from "../../assets/footer-speakers-left.svg";
import SpeakerRight from "../../assets/footer-speakers-right.svg";
import Logo from "../../assets/footer-logo.png";
import DarkLogo from "../../assets/footer-logo-dark.png";
import "./AppFooter.scss";

const AppFooter = () => {
  const context = useContext(Context);

  return (
    <footer className="footer">
      <img src={SpeakerLeft} alt="" className="footer__speaker" />
      <img
        src={context.theme === "light" ? DarkLogo : Logo}
        alt=""
        className="footer__logo"
      />
      <img src={SpeakerRight} alt="" className="footer__speaker" />
    </footer>
  );
};

export default AppFooter;
