import "./ContentContainer.scss";

const ContentContainer = (props) => {
  return (
    <div
      className={
        props.no_overflow
          ? "content_container content_container--no_overflow"
          : "content_container"
      }
    >
      {props.children}
    </div>
  );
};

export default ContentContainer;
