import { useContext } from "react";
import { Context } from "../../utils/context";
import Generate from "../../assets/generate-icon.svg";
import GenerateActive from "../../assets/generate-icon-active.svg";
import Community from "../../assets/community-icon.svg";
import CommunityActive from "../../assets/community-icon-active.svg";
import Player from "../../assets/player-icon.svg";
import PlayerActive from "../../assets/player-icon-active.svg";
import Menu from "../../assets/menu-icon.svg";
import "./Nav.scss";

const Nav = () => {
  const { tab, setTab, aiLoading } = useContext(Context);

  return (
    <nav className="nav">
      <img
        src={
          tab === "generate" ||
          tab === "generating" ||
          tab === "generated-results"
            ? GenerateActive
            : Generate
        }
        alt="Generate"
        onClick={() => {
          setTab(aiLoading ? "generating" : "generate");
        }}
      />
      <img
        src={tab === "community" ? CommunityActive : Community}
        alt="Community"
        onClick={() => {
          setTab("community");
        }}
      />
      <img
        src={!tab ? PlayerActive : Player}
        alt="Player"
        onClick={() => {
          setTab(null);
        }}
      />
      <img src={Menu} alt="Menu" onClick={() => setTab("menu")} />
    </nav>
  );
};

export default Nav;
