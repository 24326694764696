import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import "./PostModal.scss";
import { useRef } from "react";

const ImgModal = (props) => {
  const ref = useRef();

  const fileChangeHandler = () => {
    if (ref.current.files[0]) {
      props.setFile(ref.current.files[0]);
      const fr = new FileReader();

      fr.onload = function () {
        props.setImg(fr.result);
      };

      fr.readAsDataURL(ref.current.files[0]);
    } else {
      props.setImg(null);
      props.setFile(null);
    }
  };

  const clearImgHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    ref.current.value = "";
    props.setImg(null);
  };

  return (
    <div className="post_modal">
      <div className="post_modal__backdrop" onClick={props.closeModal}></div>
      <div className="post_modal__container">
        <Close onClick={props.closeModal} />
        <AppHeader text="Upload A Picture" />
        <img
          className="post_modal__img"
          alt=""
          src={props.img || Placeholder}
        />
        <div className="post_modal__buttons">
          <div className="button" onClick={clearImgHandler}>
            Clear
          </div>
          <label className="button" htmlFor="img_file">
            Upload
          </label>
          <input
            id="img_file"
            type="file"
            onChange={fileChangeHandler}
            ref={ref}
          />
        </div>
      </div>
    </div>
  );
};

export default ImgModal;
