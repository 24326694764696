import { useContext } from "react";
import { Context } from "../../../utils/context";
import Comment from "../../../assets/comment.svg";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import "./ActiveSongPreview.scss";

const ActiveSongPreview = ({ song }) => {
  const { setTab, tab } = useContext(Context);

  return (
    <div
      className="song_preview"
      style={{ paddingTop: tab === "generating" ? "5rem" : "" }}
    >
      <div className="song_preview__img_container">
        <img
          className="song_preview__img"
          src={song.imgURL ? song.imgURL : Placeholder}
          alt={song.name}
        />
      </div>
      <div className="song_preview__info">
        <h3>{song.name}</h3>
        {tab !== "generated-results" && (
          <div className="song_preview__icons">
            <img
              src={Comment}
              alt="Comments"
              title="View Comments"
              onClick={() => {
                setTab("comments");
              }}
            />
          </div>
        )}
      </div>
      <p>{song.artist}</p>
    </div>
  );
};

export default ActiveSongPreview;
