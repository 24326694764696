import { useContext } from "react";
import { Context } from "../../utils/context";
import AppFooter from "./AppFooter";
import "./AppWrapper.scss";

const AppWrapper = (props) => {
  const context = useContext(Context);
  
  return (
    <main
      className={
        context.theme === "light"
          ? "app_wrapper app_wrapper--light"
          : "app_wrapper"
      }
    >
      {props.children}
      <AppFooter />
    </main>
  );
};

export default AppWrapper;
