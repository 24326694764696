const firebaseConfig = {
  type: "service_account",
  project_id: "happy-monday-fb1b2",
  private_key_id: "c130969b679bcb810359e184073f17481bc56237",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDVD92CHEgTeGhA\nkIgWsZPlkLm2pACqWlkMZMynyprJ9Q7HHOar9X8z3MdTeXXGbD2pKevxUzSQCimY\ngzJiy3T9G7nwFKrTGz7pNGmiho0HksS6gMxNqFOOp+UjYD1wxGwN3lZsVunW7Jxj\njimcf0xc1tc+eLwHVRHXZfD1vgHEb8s4Rkoga769S5tEESGz/vWkiW6mlSKB22p1\nwSFicRG+pSNJ5nMFByhmUhuNozTyiNlR3WQ2n42Fx8v0c2sOO2hTv07hG2A1SUjh\nHwX77J/rCW9+7eN2YIWpEfY8lUt/lCsR45KpqupXsnwVVvuzQFlMsj73MzFMizVW\nAa7huH1jAgMBAAECggEAALBF1mQCNTkZy404016/8pyU7REuOCuFYPPgCwuT+nxP\nQuqsZPisvSpeforEjBKd4TKtSSD5HFB1sY3i/ZmL7dfg5VsiJJjqJO8yOpmiuD6L\nyJ238bXnwNNFroD8hQvHEAU2IRca0Sky/2Nebdul3Ie/ne6rKBiCfHOFsG85XdaM\nVwy8GFPU9PkCzzDYtF3tfhRhMHPCDJy17XD+AjgeqMAQAnzV0MB6Enl9YD31mO9r\nmk29ZL9jDpZ0Vyn2czADy7EDUwZUrxqkrKROycjYTGxerXNJiNH5c6s68UwciKbx\nNQW9FwlSIEt4PJOUOKtgkdDDUMSLMukF0pEnw20xYQKBgQD7vwps+Qs5l7P+fK0c\nKfzRkuOC7XCMfrQypP5OrrrDqSDyIl7RHWRVT8Wvm5qgIImDEZSOWWCO5wFIZEv0\nOWTpsXrsXfUhTg+x109NONpBjYGK2F6C2sPE/AuVWHhSANRstfCvcxnGj2ruqaQW\nEDNBcAQJ7yOFrIeD0821e4lcUQKBgQDYqX2u6CFTGgcTOmPoB+ojF9iaOB5rgKnU\nRxFTxqd22mKjjnSm/zb7CJLjNFkIQaigzFHnZ8kFa2R0jZywqrJ+/SZj6snFRGG+\nlJcebAGWm5MiBw5O1MXCzJfkABbkv+0eXygs+ScP8OV9aQVVBPTfTeUqPLA5da8q\noafZwON1cwKBgChcQYSlDNqn6rCTNoG8bY7/+L/F53Ru3976utbMhp7V37VSM1dd\nWfefeXclAE5JySIdUG7d/3AJsxfzFIZMKVSaIrYAfktfo36EoW8k/UDjOfCTrdtW\nzWooaocADxI+lc/z/2lxj6FY3vDye58Z683C8rLRByXoOjW1YFaHPsdxAoGBAL5R\nI/XgMmWtF0nsoJXRXfo1mC7f3NjRzFyLkMzmU3QzoTHKkO2ggctBXMX31b1K77VP\nxSJgKFR6YWOAgYMublzeQjrCozqFNtcR7yzp8vs47aWWTRsyDAVxcnr1GScW8FMd\ng0z+DzIRiYUAG47zK6Z75jGT3bibh6MLHUZKCYPBAoGBALx4PlMRqPpuBfrv9iTW\nwz0OsALNjZT4eZCIEsAVM7r59vrllOkhJAdTmJOXyxorjLygRv0FlpPk2AR0GtzE\nccV+16eiFydvR936CiVS0IK/UO39xN3N9ooZkpAZ6dfSqoksFN2xEgGyZQCcqw3q\nCCn7Lewc34b4/g8ycbBF+0pQ\n-----END PRIVATE KEY-----\n",
  client_email:
    "firebase-adminsdk-9mm4j@happy-monday-fb1b2.iam.gserviceaccount.com",
  client_id: "100102213492421462120",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-9mm4j%40happy-monday-fb1b2.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
  storageBucket: "gs://happy-monday-fb1b2.appspot.com",
};

export default firebaseConfig;
