import { useContext, useState } from "react";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import timeSince from "../../../utils/time-since";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Delete from "../../../assets/delete.svg";
import Pin from "../../../assets/pin.svg";
import PinFilled from "../../../assets/pin-filled.svg";
import Fire from "../../../assets/fire.svg";
import FireFilled from "../../../assets/fire-filled.svg";
import Verified from "../../../assets/verified-icon.png";
import Comment from "../../../assets/comment.svg";
import SongTile from "../../songs/components/SongTile";
import "./Post.scss";

function linkify(text) {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const parts = text
    .split(urlRegex)
    .filter((p) => p !== "http" && p !== "https");

  return parts.map((part, index) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    return part;
  });
}

const Post = (props) => {
  const context = useContext(Context);
  const { sendRequest } = useHttpClient();

  const likePostHandler = async () => {
    props.likePost(props.id, context.userId);

    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/community/like",
      "POST",
      JSON.stringify({
        like: !props.likes.find((l) => l === context.userId),
        userId: context.userId,
        postId: props.id,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );
  };

  const deleteHandler = async (e) => {
    e.preventDefault();
    props.deleteHandler(props.id);
  };

  const pinHandler = async (e) => {
    e.preventDefault();
    props.pinHandler(props.id, !props.pinned);
  };

  return (
    <div className="post">
      <div className="post__header">
        <img
          className="post__img"
          src={props.creator.img ? props.creator.img : Placeholder}
          alt=""
        />

        <div>
          <div className="post__name">
            <span>{props.creator.name}</span>
            {props.creator.verified && <img src={Verified} alt="Verified" />}
          </div>
          <p className="post__date">{timeSince(props.date)}</p>
        </div>

        <div className="post__icons">
          {(props.pinned || context.admin) && (
            <img
              onClick={pinHandler}
              src={props.pinned ? PinFilled : Pin}
              alt="Pin"
            />
          )}
          {(context.admin || context.userId === props.creator.id) && (
            <img src={Delete} alt="Delete" onClick={deleteHandler} />
          )}
          <div className="post__like" onClick={likePostHandler}>
            <img
              src={
                props.likes.find((l) => l === context.userId)
                  ? FireFilled
                  : Fire
              }
              alt="like"
            />
            <span>{props.likes.length}</span>
          </div>

          <div
            className="post__comment"
            onClick={() => {
              context.setActivePost(props.id);
              context.setTab("community_comments");
            }}
          >
            <img src={Comment} alt="Comment" />
            <span>{props.comments.length}</span>
          </div>
        </div>
      </div>

      <p className="post__par">{linkify(props.text)}</p>

      {props.imgURL && (
        <img className="post__post_img" src={props.imgURL} alt="" />
      )}

      {props.song && (
        <div className="post__song_container">
          <SongTile {...props.song} num="" />
        </div>
      )}

      {props.youtube && (
        <div className="post__embed_container">
          <iframe
            src={props.youtube}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}

      {props.tiktok && (
        <div className="post__tiktok_container">
          <iframe
            src={props.tiktok}
            title="TikTok video player"
            allow="encrypted-media;"
            frameborder="0"
            scrolling="no"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default Post;
