import { useContext } from "react";
import { Context } from "../../utils/context";
import PlayerButtons from "./components/PlayerButtons";
import PlayerHeader from "./components/PlayerHeader";
import PlayerControls from "./components/PlayerControls";
import SongCommentForm from "../comments/components/SongCommentForm";
import PostCommentForm from "../comments/components/PostCommentForm";
import PostForm from "../community/components/PostForm";
import AIButtons from "./components/AIButtons";
import "./Player.scss";

const Player = () => {
  const context = useContext(Context);

  return (
    <div>
      <PlayerHeader />
      <div className="player_body">
        {(!context.tab ||
          context.tab === "generate" ||
          context.tab === "control-panel") && <PlayerButtons />}
        {context.tab === "generated-results" && <AIButtons />}
        {context.tab === "community" && <PostForm />}
        {context.tab === "comments" && <SongCommentForm />}
        {context.tab === "community_comments" && <PostCommentForm />}
        <PlayerControls />
      </div>
    </div>
  );
};

export default Player;
