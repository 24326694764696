import { useContext, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHttpClient } from "../../../utils/http-hook";
import { Context } from "../../../utils/context";
import { firebaseUpload, firebaseDelete } from "../../../utils/upload-hook";
import AppHeader from "../../shared/AppHeader";
import Notification from "../../shared/Notification";
import Input from "../../shared/Input";
import Placeholder from "../../../assets/placeholder-tile.jpg";
import Loader from "../../shared/Loader";
import Delete from "../../../assets/delete-light.svg";
import Download from "../../../assets/download.svg";
import Close from "../../shared/Close";
import "./RemixInfo.scss";

const EditRemix = () => {
  const context = useContext(Context);
  const { sendRequest, isLoading } = useHttpClient();

  const deleteSong = async () => {
    if (context.activeRemix.imgPath)
      firebaseDelete(context.activeRemix.imgPath);
    if (context.activeRemix.songPath)
      firebaseDelete(context.activeRemix.songPath);

    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/songs/",
      "DELETE",
      JSON.stringify({
        songId: context.activeRemix._id,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    context.setSongs((songs) =>
      songs.filter((s) => s._id !== context.activeRemix._id)
    );
    context.setTab();
  };

  const privatePublicHandler = () => {
    context.setActiveRemix((song) => {
      return { ...song, published: !song.published };
    });
  };

  const ref = useRef();
  const fileChangeHandler = () => {
    const fr = new FileReader();

    fr.onload = function () {
      context.setActiveRemix((song) => {
        return { ...song, imgURL: fr.result };
      });
    };

    fr.readAsDataURL(ref.current.files[0]);
  };

  const submitHandler = () => {
    const file = ref.current.files[0];

    if (file) {
      if (context.activeRemix.imgPath)
        firebaseDelete(context.activeRemix.imgPath);

      const path = `Pictures/SongsPics/${uuidv4()}.${file.name
        .split(".")
        .pop()}`;

      const [getUploadTask, getDownloadImgURL] = firebaseUpload(path, file);

      const uploadTask = getUploadTask();
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadImgURL(uploadTask.snapshot.ref).then((imgURL) => {
            context.setActiveRemix((song) => {
              return { ...song, imgURL, imgPath: path };
            });
          });
        }
      );
    }
  };

  const saveSong = async () => {
    await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/save",
      "POST",
      JSON.stringify({
        imgURL: context.activeRemix.imgURL,
        imgPath: context.activeRemix.imgPath,
        name: context.activeRemix.name,
        artist: context.activeRemix.artist,
        songId: context.activeRemix._id,
        published: context.activeRemix.published,
      }),
      {
        Authorization: "Bearer " + context.token,
        "Content-Type": "application/json",
      }
    );

    context.setSongs((songs) => {
      const index = songs.findIndex((s) => s._id === context.activeRemix._id);
      songs[index] = context.activeRemix;
      return songs;
    });
  };

  useEffect(() => {
    saveSong();
  }, [context.activeRemix]);

  return (
    <div className="col col__1">
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Edit Song" />
      <Close onClick={() => context.setTab()} />

      <div className="remix_info">
        <input
          type="file"
          accept="image/*"
          id="remix_img"
          ref={ref}
          onChange={fileChangeHandler}
        />
        <div className="remix_info__details">
          <label htmlFor="remix_img">
            <img src={context.activeRemix.imgURL || Placeholder} alt="" />
          </label>
          <div>
            <h3>{context.activeRemix.name}.MP5</h3>
            <p>{context.activeRemix.artist}</p>
          </div>
        </div>

        <div className="remix_info__inputs">
          <Input
            placeholder="Song Name"
            value={context.activeRemix.name}
            type="text"
            onInput={(e) =>
              context.setActiveRemix((song) => {
                return { ...song, name: e.target.value };
              })
            }
            required
          />
          <Input
            placeholder="Artist"
            value={context.activeRemix.artist}
            type="text"
            onInput={(e) =>
              context.setActiveRemix((song) => {
                return { ...song, artist: e.target.value };
              })
            }
            required
          />
          <>
            <div className="remix_info__buttons">
              <div
                className="remix_info__button"
                onClick={() => privatePublicHandler()}
              >
                {context.activeRemix.published ? "Make Private" : "Make Public"}
              </div>

              <div className="remix_info__button" onClick={submitHandler}>
                Save
              </div>
            </div>

            <div className="remix_info__buttons">
              <div className="remix_info__button" onClick={deleteSong}>
                <span>Delete</span>
                <img src={Delete} alt="" />
              </div>

              <a
                className="remix_info__button"
                href={context.activeRemix.songURL}
                download={context.activeRemix.name + " DEMO.MP5"}
                target="_blank"
                rel="noreferrer"
              >
                <span>Download</span>
                <img src={Download} alt="" />
              </a>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default EditRemix;
